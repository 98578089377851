import React from "react";
import { Container, Flex } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import Divider from "@solid-ui-components/Divider";
import StackTitle from "@solid-ui-components/StackTitle";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import ContentImages from "@solid-ui-components/ContentImages";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import Blog from "@solid-ui-blocks/Blog/Block02";

const FeaturesWithPhotoBlock09 = ({
  content: { kicker, title, text_1, text_2, images, collection }, reverse,
}) => (
  <Container sx={{ position: `relative` }}>
    {kicker.show &&
      <>
        <Flex
          sx={{
            alignItems: [null, `center`],
            flexDirection: [
              reverse ? `column-reverse` : `column`,
              reverse ? `row-reverse` : `row`,
            ],
            mx: [null, null, null, -4],
          }}
        >
          <FlexImage reverse={reverse}>
            <ContentImages content={{ images }} reverse={reverse} />
          </FlexImage>
          <FlexContent reverse={reverse}>
            <StackTitle
              kicker={kicker}
              title={title}
              lines={[text_1, text_2]}
              align='left'
              space={0}
            />
          </FlexContent>
        </Flex>
        <Divider space="5" />
      </>
    }
    <Reveal effect="fadeInDeepLeft" duration={1}>
      <Blog content={{ collection }} />
    </Reveal>
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock09);
