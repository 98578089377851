import React from 'react'
import { Container, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const FeaturesWithPhotoBlock03 = ({
  content: { title, kicker, text_1, text_2, buttons, images }
}) => (
  <Container sx={{ textAlign: `center` }}>
    <Box>
      <Reveal effect='fadeInDown'>
        <StackTitle
          kicker={kicker}
          title={title}
          lines={[text_1, text_2]}
          space={0}
        />
      </Reveal>
      <Reveal effect='fadeInUp' duration={0.7} css={css({ mb: 5 })}>
        <ContentButtons content={buttons} space={3} />
      </Reveal>
    </Box>
    <Box sx={{ position: `relative` }}>
      <ContentImages
        content={{ images }}
        loading='eager'
        imagePosition='center'
        imageEffect='fadeInDown'
      />
    </Box>
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock03)
